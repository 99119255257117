<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h2>설정 관리</h2>
        <v-card>
          <v-card-text>
            설정 관리 페이지입니다.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MyfinbizSettings',
  data() {
    return {
      // 데이터 초기화
    }
  },
  methods: {
    // 메소드 정의
  }
}
</script>
